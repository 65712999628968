import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import moment from 'moment-timezone';

import routesMap from '../../Routes';
import withOrderContext from '../../withOrderContext';
import momentFr from '../../config/moment.fr';
import {
  colors, mobileThresholdPixels, margins,
  StyledTitle2 as Title2,
} from '../home/v3/styledComponents';
import LogoButton from './LogoButton';
import Calendar from './Calendar';
import SlotSelector from './SlotSelector';
import SlotsSummary from './SlotsSummary';
import PickupList from './PickupList';
import calendar from '../../assets/calendar.svg';
import calendarChecked from '../../assets/calendarChecked.svg';
import clock from '../../assets/clock.svg';
import { dateFormat, getActiveSlots } from '../../services/slotsFormatting';
import {
  FormInput, Label, PopUpText, PopUpWaitTime,
} from '../../pagesComponents/order/containers/InfosContainer';
import tree, { treeInverse } from './tree';
import { pricingInverse, metadata } from '../../services/pricing';
import { getDeliveryFees } from '../../services/zipcode';
import { formatCloth, formatSlots } from '../../services/orderFormatting';
import callApi from '../../services/api';
import Dots from '../home/v3/Dots';
import withAuthContext from '../../context/withAuthContext';
import featureSwitch from '../../services/featureSwitch';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const isEqualDays = (selectedDay, day) => selectedDay && selectedDay.isSame(day, 'day');

function getBeginHour(selectedDay) {
  const now = moment();
  const tomorrow = moment().add(1, 'day');
  if (isEqualDays(selectedDay, now)) return Math.max(13, now.hours() + 4);
  if (isEqualDays(selectedDay, tomorrow) && now.hours() > 18) return 13;
  return 7;
}

function createSlot(hours, is30 = false) {
  return {
    hours,
    minutes: is30 ? 30 : 0,
    string1: is30 ? `${hours}h30` : `${hours}h`,
    hoursEnd: is30 ? hours + 1 : hours,
    minutesEnd: is30 ? 0 : 30,
    string2: is30 ? `${hours + 1}h` : `${hours}h30`,
    isActive: false,
  };
}

function createSlots(beginHour, endHour, removeLastOne = true) {
  const slots = [];
  let slotHour;
  for (slotHour = beginHour; slotHour <= endHour; slotHour += 1) {
    slots.push(createSlot(slotHour));
    slots.push(createSlot(slotHour, true));
  }
  if (removeLastOne) slots.pop();
  return slots;
}

const P = styled.p`
  font-family: Roboto;
  font-weight: ${({ blue }) => blue ? 'bold' : 'normal'};
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${({ blue }) => blue ? colors.blue : colors.navy};
  margin: ${({ blue }) => blue ? '10px 80px -20px' : '40px 80px 69px'};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 0px 40px;
    line-height: 17px;
    font-size: 14px;
    padding: 20px 30px 25px;
    margin: 0px;
  }
`;

const ComponentTitle = styled.p`
  font-family: Gotham;
  line-height: 28px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: ${(props) => props.noMarginTopAndBottom ? '0px 0px 0px 8px' : '30px 0px 30px 8px'};
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 5px 8px;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  margin-bottom: ${(props) => props.largeMarginBottom ? '60' : '23'}px;
  ${(props) => props.noMarginBottom && 'margin-bottom: 0px;'}
  ${(props) => props.marginTop && 'margin-top: 100px;'}
  ${(props) => props.column && 'flex-direction: column; align-items: flex-start;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${(props) => props.mobileReverse ? 'column-reverse' : 'column'};
    align-items: center;
    ${(props) => props.marginTop && 'margin-top: 60px;'}
    ${(props) => !props.noMarginBottom && 'margin-bottom: 20px;'}
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.borderBottom && `border-bottom: 0.75px solid ${colors.lightGrey2};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const ComponentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const TopLinkContainer = styled.div`
  position: relative;
  top: -${margins.l};
  @media (max-width: ${mobileThresholdPixels}) {
    top: -${margins.m};
    margin-bottom: ${margins.s};
    margin-top: ${margins.s};
    text-align: center;
  }
`;

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
  margin-top: -22px;
  margin-bottom: 44px;
`;

const PickupContainer = styled.div`
  margin: -60px 0px 80px;
  text-align: center;
`;

const PickupTitle = styled.p`
  font-family: Roboto;
  font-weight: bold;
  line-height: 28px;
  font-size: 18px;
  text-align: center;
  color: ${colors.blue};
`;

function fetchCurrentOrders() {
  return callApi('orders/pickups').then(({ orders }) => (process.env.GATSBY_ENV !== 'PROD' && orders.length > 2)
    ? { orders: orders.slice(orders.length - 2) }
    : { orders });
}

class Availabilities extends React.Component {
  constructor(props) {
    super(props);
    const initialState = props.orderContext.slots[moment().format(dateFormat)]
      || {
        allDay: { isActive: false, text: 'Toute la journée' },
        slotBlocks: [
          { isActive: false, text: 'Toute la matinée', slots: createSlots(7, 12, false) },
          { isActive: false, text: "Tout l'après-midi", slots: createSlots(13, 17, false) },
          { isActive: false, text: 'Toute la soirée', slots: createSlots(18, 21) },
        ],
      };
    this.state = {
      ...initialState,
      selectedDates: props.orderContext.slots || {},
      date: getBeginHour(moment()) > 21 ? moment().add(1, 'day') : moment(),
    };
    this.submitOrder = this.submitOrder.bind(this);
    this.toggleSlot = this.toggleSlot.bind(this);
    this.togglePartDay = this.togglePartDay.bind(this);
    this.toggleAllDay = this.toggleAllDay.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.selectDateAndSlot = this.selectDateAndSlot.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goToOrderSummary = this.goToOrderSummary.bind(this);
  }

  componentDidMount() {
    const { authContext: { shop: { customerForRetail } }, orderContext: { rdv1Location } } = this.props;
    if (customerForRetail && rdv1Location === 'atShop') {
      fetchCurrentOrders(customerForRetail._id).then(({ orders }) => {
        this.setState({ orders });
      });
    }
  }

  goNext() {
    const { setSlots } = this.props.orderContext;
    const { selectedDates } = this.state;
    setSlots(selectedDates);
    navigate(routesMap.Step3.url);
  }

  submitOrder() {
    const { selectedDates, seller } = this.state;
    const { authContext: { shop: { customerForRetail, address, _id } }, orderContext: { clothes } } = this.props;
    const rdv1Slots = formatSlots(selectedDates);
    const customer = { _id: customerForRetail?._id };
    const brand = process.env.GATSBY_BRAND;
    const orderRequest = {
      rdv1: rdv1Slots[0],
      rdv1Slots,
      fabrics: [],
      promoCode: featureSwitch('step3_atShopPromoCode'),
      minOrderAmount: metadata.minOrderAmountAtShop,
      deliveryFee: getDeliveryFees(address?.zipcode),
      pricingVersion: metadata.version,
      clothes: clothes.map((cloth) => formatCloth(tree, treeInverse, pricingInverse, cloth, brand)),
      seller,
      brand,
      shop: _id,
      cardToken: '1',
      process: 'AT_SHOP',
    };
    const request = { order: orderRequest, customer };
    this.setState({ popUpWaitTimeIsOpen: true });
    callApi('editCustomerAndOrder', 'post', request)
      .then(() => {
        this.setState({ popUpWaitTimeIsOpen: false });
        navigate(routesMap.Step4.url);
      })
      .catch((response) => {
        this.setState({ popUpWaitTimeIsOpen: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  goToOrderSummary() {
    const { orderContext: { setSlots, clothes, setIsFastVariant } } = this.props;
    setIsFastVariant(false);
    const { selectedDates } = this.state;
    const route = clothes.length === 0 ? routesMap.Step1.url : routesMap.Step1Summary.url;
    setSlots(selectedDates);
    navigate(route);
  }

  selectDateAndSlot({ allDay, slotBlocks }) {
    const { selectedDates, date } = this.state;
    const newSelectedDates = { ...selectedDates };
    newSelectedDates[date.format(dateFormat)] = {
      date: moment(date),
      allDay: { ...allDay },
      slotBlocks: [...slotBlocks],
    };
    this.setState({
      allDay,
      slotBlocks,
      selectedDates: { ...newSelectedDates },
    });
  }

  toggleAllDay() {
    const { allDay, slotBlocks } = this.state;
    slotBlocks.forEach((partDaySlots, index) => {
      if (partDaySlots.isActive === allDay.isActive) {
        this.togglePartDay(index);
      }
    });

    this.setState({
      allDay: {
        ...allDay,
        isActive: !allDay.isActive,
      },
    });
  }

  togglePartDay(indexPartDay) {
    const { allDay, slotBlocks } = this.state;
    const newSlotBlocks = [...slotBlocks];
    newSlotBlocks[indexPartDay].isActive = !newSlotBlocks[indexPartDay].isActive;
    newSlotBlocks[indexPartDay].slots = newSlotBlocks[indexPartDay].slots
      .map((slot) => ({ ...slot, isActive: newSlotBlocks[indexPartDay].isActive }));
    const slotBlocksAreAllActives = newSlotBlocks.reduce((acc, slotBlock) => acc ? slotBlock.isActive : false, true);
    if (slotBlocksAreAllActives) {
      this.toggleAllDay();
    } else {
      this.selectDateAndSlot({
        allDay: { ...allDay, isActive: false },
        slotBlocks: [...newSlotBlocks],
      });
    }
  }

  toggleSlot(indexPartDay, indexSlot) {
    const { allDay, slotBlocks } = this.state;
    const newSlotBlocks = [...slotBlocks];
    newSlotBlocks[indexPartDay].slots[indexSlot].isActive = !newSlotBlocks[indexPartDay].slots[indexSlot].isActive;
    const slotsAreAllActives = newSlotBlocks[indexPartDay].slots
      .reduce((acc, slot) => acc ? slot.isActive : false, true);
    if (slotsAreAllActives) {
      this.togglePartDay(indexPartDay);
    } else {
      if (!newSlotBlocks[indexPartDay].slots[indexSlot].isActive && newSlotBlocks[indexPartDay].isActive) {
        newSlotBlocks[indexPartDay].isActive = false;
      }
      this.selectDateAndSlot({
        allDay: { ...allDay, isActive: false },
        slotBlocks: [...newSlotBlocks],
      });
    }
  }

  selectDate(selectedDate) {
    const { date, selectedDates } = this.state;
    if (date !== selectedDate) {
      const newSlotBlocks = selectedDates[selectedDate.format(dateFormat)]
        ? [...selectedDates[selectedDate.format(dateFormat)].slotBlocks]
        : [
          { isActive: false, text: 'Toute la matinée', slots: createSlots(7, 12, false) },
          { isActive: false, text: "Tout l'après-midi", slots: createSlots(13, 17, false) },
          { isActive: false, text: 'Toute la soirée', slots: createSlots(18, 21) },
        ];
      const newAllDay = selectedDates[selectedDate.format(dateFormat)]
        ? { ...selectedDates[selectedDate.format(dateFormat)].allDay }
        : { isActive: false, text: 'Toute la journée' };
      this.setState({
        allDay: { ...newAllDay },
        slotBlocks: [...newSlotBlocks],
        date: moment(selectedDate),
      });
    }
  }

  renderCalendar() {
    const {
      allDay, slotBlocks, selectedDates, date,
    } = this.state;
    const { orderContext: { rdv1Location } } = this.props;
    const beginHour = getBeginHour(date);
    return (
      <>
        <ComponentsContainer>
          <ComponentContainer borderBottom>
            <ComponentHeader>
              <img src={calendar} alt="Sélectionnez plusieurs dates" />
              <ComponentTitle>Sélectionnez plusieurs dates</ComponentTitle>
            </ComponentHeader>
            <Calendar selectDate={this.selectDate} selectedDate={date} selectedDates={selectedDates} />
          </ComponentContainer>
          <ComponentContainer>
            <ComponentHeader>
              <img src={clock} alt="Sélectionnez plusieurs créneaux" />
              <ComponentTitle>Et plusieurs créneaux</ComponentTitle>
            </ComponentHeader>
            <SlotSelector
              allDay={allDay}
              slotBlocks={slotBlocks}
              beginHour={beginHour}
              toggleAllDay={this.toggleAllDay}
              togglePartDay={this.togglePartDay}
              toggleSlot={this.toggleSlot}
            />
          </ComponentContainer>
        </ComponentsContainer>
        <ComponentsContainer justifyContent="flex-start" column largeMarginBottom>
          <ComponentHeader>
            <img src={calendarChecked} alt="Créneaux sélectionnés" />
            <ComponentTitle noMarginTopAndBottom>Créneaux sélectionnés</ComponentTitle>
          </ComponentHeader>
          <ComponentContainer>
            {selectedDates && Object.keys(selectedDates).length > 0 && <SlotsSummary selectedDates={selectedDates} rdv1Location={rdv1Location} />}
          </ComponentContainer>
        </ComponentsContainer>
      </>
    );
  }

  renderEstimationLink() {
    const { orderContext: { isFastVariant, setEditingClothIndex, rdv1Location } } = this.props;
    if (!isFastVariant || rdv1Location !== 'atShop') return null;
    return (
      <TopLinkContainer>
        <Link
          to={routesMap.Step1.url}
          onClick={() => setEditingClothIndex(null)}
          style={{ color: colors.navy }}
        >
          Cliquer ici pour faire l’estimation de mon client
        </Link>
      </TopLinkContainer>
    );
  }

  renderSeller() {
    const { orderContext: { rdv1Location } } = this.props;
    const { seller } = this.state;
    if (rdv1Location !== 'atShop') return null;
    return (
      <FormInputContainer>
        <Label>Nom du vendeur</Label>
        <FormInput
          name="Vendeur"
          value={seller || ''}
          type="text"
          size="large"
          isMobileLarge
          onChange={({ target }) => this.setState({ seller: target.value })}
        />
      </FormInputContainer>
    );
  }

  renderButtons() {
    const { orderContext: { rdv1Location } } = this.props;
    const { selectedDates, seller } = this.state;
    const activeSlots = getActiveSlots(selectedDates);
    const nextButtonWording = rdv1Location === 'atShop' ? 'Je valide la prise de RDV' : 'Dernière étape, l‘adresse';
    const nextAction = rdv1Location === 'atShop' ? this.submitOrder : this.goNext;
    return (
      <ComponentsContainer mobileReverse>
        <StyledLink onClick={this.goToOrderSummary}>
          <LogoButton back noZoom>Modifier la commande</LogoButton>
        </StyledLink>
        {activeSlots.length > 0 && (rdv1Location !== 'atShop' || seller) ? (
          <StyledLink onClick={nextAction}>
            <LogoButton>{nextButtonWording}</LogoButton>
          </StyledLink>
        ) : (<LogoButton isAvailable={false}>{nextButtonWording}</LogoButton>)}
      </ComponentsContainer>
    );
  }

  renderPickups() {
    const { orderContext: { rdv1Location } } = this.props;
    const { orders } = this.state;
    if (rdv1Location !== 'atShop') return null;
    return (
      <PickupContainer>
        {orders?.length > 0 && (
          <>
            <PickupTitle>
              Pickups en cours :
            </PickupTitle>
          </>
        )}
        <PickupList orders={orders} />
      </PickupContainer>
    );
  }

  render() {
    const { orderContext: { rdv1Location } } = this.props;
    const { popUpWaitTimeIsOpen } = this.state;
    return (
      <div>
        <PopUpWaitTime isDisplayed={popUpWaitTimeIsOpen}>
          <Dots size={9} spacing={22} />
          <PopUpText marginTop={25}>Création de la commande en cours</PopUpText>
          <PopUpText>Merci de patienter quelques instants</PopUpText>
        </PopUpWaitTime>
        <Title2>
          {rdv1Location === 'atShop'
            ? 'Quand voulez-vous que votre Tilliste passe en boutique ?'
            : 'Quand votre client est disponible ?'}
        </Title2>
        {rdv1Location === 'atShop' && (
          <P blue>
            Aucun pickup en cours pour un rendu imminent ?
          </P>
        )}
        <P>
          {rdv1Location === 'atShop'
            ? 'Vérifiez que vous n’avez pas un Tilliste en cours de PickUp qui pourrait à son retour repartir avec cette commande !'
            : `Pendant le RDV à domicile, le couturier valide avec votre client ses attentes,
            prend ses mesures et marque les retouches.
            Il repart ensuite avec le vêtement dans son atelier.`}
        </P>
        {this.renderPickups()}

        {this.renderEstimationLink()}
        {this.renderSeller()}
        {this.renderCalendar()}
        {this.renderButtons()}
      </div>
    );
  }
}

Availabilities.propTypes = {
  orderContext: PropTypes.shape({
    setSlots: PropTypes.func.isRequired,
    slots: PropTypes.shape({}),
    isFastVariant: PropTypes.bool,
    setEditingClothIndex: PropTypes.func,
    setIsFastVariant: PropTypes.func,
    rdv1Location: PropTypes.string,
    clothes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  authContext: PropTypes.shape({
    shop: PropTypes.shape({
      _id: PropTypes.string,
      customerForRetail: PropTypes.shape({
        _id: PropTypes.string,
      }),
      address: PropTypes.shape({
        zipcode: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default withAuthContext(withOrderContext(Availabilities));
