import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const MainContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: 427px;
  background-color: ${colors.white};
  padding: 15px 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0;
    width: 75vw;
  }
`;

const Slots = styled.div`
  display: flex;
  flex-direction: column;
  width: 203px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  ${(props) => props.isActive ? `background-color: ${colors.navy};` : props.linen && `background-color: ${colors.linen};`}
  color: ${(props) => props.isActive ? colors.white : colors.navy};
  ${(props) => !props.isAvailable && 'opacity: 0.4; pointer-events: none;'}
  height: ${(props) => props.small ? '1' : '34'}px;
  margin-top: ${(props) => props.small ? '4' : '0'}px;
  width: ${(props) => props.full ? '100%' : '95%'};
  ${(props) => props.marginTop && 'margin-top: 16px;'}
  ${(props) => props.marginBottom && 'margin-bottom: 3px;'}
  cursor: pointer;
`;

const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Title = styled.p`
  font-family: Gotham;
  line-height: 20px;
  font-size: 14px;
  margin: 0;
`;

const Text = styled.p`
  font-family: Roboto;
  line-height: 24px;
  font-size: 13px;
  margin: 0;
`;

const Hours = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 35px;
  margin: 2px 0;
  width: 45%;
`;

const Line = styled.div`
  position: relative;
  flex: 1;
  height: 1px;
  width: 100%;
  background-color: ${colors.blueGrey};
`;

const SlotSelector = ({
  allDay, slotBlocks, toggleAllDay, togglePartDay, toggleSlot, beginHour,
}) => (
  <MainContainer>
    <Container
      full
      linen
      onClick={toggleAllDay}
      isActive={allDay.isActive}
      isAvailable={beginHour === slotBlocks[0].slots[0].hours}
    >
      <Title>{allDay.text}</Title>
    </Container>
    <SubContainer>
      {slotBlocks && slotBlocks.map((partDaySlots, indexPartDay) => (
        <Slots key={partDaySlots.text}>
          <Container
            full
            linen
            marginTop
            marginBottom
            onClick={() => togglePartDay(indexPartDay)}
            isActive={partDaySlots.isActive}
            isAvailable={beginHour <= partDaySlots.slots[0].hours}
          >
            <Title>{partDaySlots.text}</Title>
          </Container>
          <Hours>
            {partDaySlots.slots.map((slot, indexSlot) => (
              <SlotContainer key={slot.string1}>
                <Container
                  onClick={() => toggleSlot(indexPartDay, indexSlot)}
                  isActive={slot.isActive}
                  isAvailable={beginHour <= slot.hours}
                >
                  <Text>
                    {slot.string1}
                    {' '}
                    -
                    {slot.string2}
                  </Text>
                </Container>
                {(indexSlot !== partDaySlots.slots.length - 1)
                  && (partDaySlots.slots.length % 2 !== 0 || indexSlot !== partDaySlots.slots.length - 2)
                  && <Container small full><Line /></Container>}
              </SlotContainer>
            ))}
          </Hours>
        </Slots>
      ))}
    </SubContainer>
  </MainContainer>
);

SlotSelector.propTypes = {
  allDay: PropTypes.shape({}).isRequired,
  slotBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleAllDay: PropTypes.func.isRequired,
  togglePartDay: PropTypes.func.isRequired,
  toggleSlot: PropTypes.func.isRequired,
  beginHour: PropTypes.number.isRequired,
};

export default SlotSelector;
